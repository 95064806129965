@import 'font-awesome/css/font-awesome.min.css';
@import './theme/index.css';

:global {
  & body.dark {
    font-family: Heebo, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  & body.bm {
    font-family: Gloriola, Arial, sans-serif;
  }

  & body.igf {
    font-family: Gloriola, Arial, sans-serif;
  }
}
