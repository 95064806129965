* {
  margin: 0px;
  padding: 0px;
}

.main-container {
  overflow-x: hidden;
  overflow-y: hidden;
  display: inline-block;
}

.sidebar-container {
  display: inline-block;
  width: 20vw;
  min-width: 300px;
  height: 100vh;
  position: absolute;
  right: 0px;
  z-index: 999;
}

.footer-container {
  width: 100vw;
  display: inline-block;
  position: fixed;
  bottom: -4px;
}

.layout {
  position: relative;
  top: -10px;
  left: -5px;
  height: calc(100vh - 65px);
  margin-right: -10px;
  margin-bottom: -15px;
  min-width: calc(100vw - 80px);
}

#flexlayout__tab_button {
  background-color: 'red !important';
}

.hide {
  display: none;
}

.show-button,
.hide-button {
  width: 100%;
  text-align: center;
  background-color: #3f51b5;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  color: gainsboro;
}

.show-button {
  position: absolute;
  bottom: 0px;
}

.hide-button {
  position: relative;
}

.spot-add {
  margin-left: 95%;
  margin-top: 5px;
  cursor: pointer;
}

.spots-container {
  display: flex;
  flex-wrap: wrap;
}

.loading-table {
  height: 30vh;
  background-color: white;
  width: 100%;
  text-align: center;
  & .loading-icon {
    position: relative;
    top: 8vh;
  }
}

/*.add-workspace {
  position: absolute;
  right: 45px;
  bottom: 8px;
}*/

/*.logout {
  position: absolute;
  right: 0px;
  bottom: 7px;
}*/

/*.account-list {
  position: absolute;
  right: 110px;
  width: 150px;
}*/

.drag-text {
  background-image: url('../../img/CloseBlack.svg');
  background-size: 80%;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  background-position: left;
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.select-account {
  background-color: #1c2c4b !important;
  border: none;
  color: white !important;
  padding: 5px 10px;
  font-size: 16px;
  width: 170px;
}

.logo {
  height: 30px;
  position: relative;
  left: -10px;
}

.anyadir-menu {
  height: 18px;
}

/*.input {
  background-color: #283e60;
  color: #fff !important;
  border: none;
  padding-top: 2px;
  font-size: 1.1em;
  & * {
    color: #fff;
  }
}*/
