:global .btn {
  &:focus,
  &.focus,
  &:active &:active:focus,
  &:active.focus,
  &.active:focus,
  &.active.focus {
    outline: none;
    text-decoration: none;
    text-shadow: none;
  }
}
