/*
 General
 --------------------------------------------------
 Use this to add CSS rules according to the most generic html elements like
 `html`, `body` etc. and to override bootstrap.

 require("file?name=[path][name].[ext]?[hash]!../../img/bg.png");
*/

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
 ------------------------
 Centering in the unknown
 https://css-tricks.com/centering-in-the-unknown/
 */

.v-centered-parent {
  /* May want to do this if there is risk the container
    may be narrower than the element inside */
  /* white-space: nowrap; */
  /* The ghost, nudged to maintain perfect centering */
  & :before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -0.25em;
    /* Adjusts for spacing */
  }
  /* The element to be centered */
  & .v-centered {
    display: inline-block;
    vertical-align: middle;
  }
}

/* ------------------------ */

.green-color {
  color: green;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

h3,
h4,
h5 {
  font-weight: 600;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 17px;
}

.form-control,
.btn {
  border-radius: 0;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.fa {
  color: var(--blue);
}

ul.MuiList-root-91.MuiList-padding-92 {
  padding-top: 0px;
  padding-bottom: 0px;
}

label[class^='MuiFormLabel-root-'] {
  color: black;
}

fieldset[class^='MuiPrivateNotchedOutline-root-'] {
  border: none;
}

input[type='date']::-webkit-clear-button {
  -webkit-appearance: none;
  display: block;
  width: 14px;
  height: 10px;
  background-image: url('../../img/Close.svg');
  background-repeat: no-repeat;
  margin-left: 5px;
  background-size: 10px;
  background-position: top left;
}

input[type='date']::-moz-clear {
  display: none;
  color: white;
}
