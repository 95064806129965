.table {
  /* & > thead > tr > th:not(:last-child),
  & > thead > tr > td:not(:last-child) {
    border-right: 1px solid #ddd;
  } */

  & > tbody > tr > td,
  & > tbody > tr > th,
  & > tfoot > tr > td,
  & > tfoot > tr > th,
  & > thead > tr > td,
  & > thead > tr > th {
    padding: 4px 8px;
  }

  & > thead > tr > td,
  & > thead > tr > th {
    padding: 4px 8px;
    font-size: 11px;
    line-height: 18px;
    box-shadow: inset 0 1px 0 #ffffff, inset 0 -1px 0 #e8e8e8, 0 1px 1px -1px rgba(0, 0, 0, 0.15);
  }
  & > tbody > tr > td,
  & > tbody > tr > th {
    font-size: 10px;
  }

  /* remove border-bottom of all the last rows (of header, body and footer) */
  & > thead > tr:last-child > th,
  & > thead > tr:last-child > td,
  & > tbody > tr:last-child > th,
  & > tbody > tr:last-child > td,
  & > tfoot > tr:last-child > th,
  & > tfoot > tr:last-child > td {
    border-bottom-width: 0;
  }

  & > tbody > tr:first-child > th,
  & > tbody > tr:first-child > td {
    border-top: 1px solid #ddd; /* $tableBorder; */
  }

  & > tbody {
    background-color: var(--tableBackground);
  }
}

:global .esr-table .ReactTable .rt-thead.-filters input {
  /* color: var(--white); */
  height: 32px;
  border-radius: 0;
  /* background-color: 'var(--bars)'; */
  /* &:hover {
    background-color: var(--hovered);
  } */
}
